<template>
  <div class="container">
    <!-------------------------------EMAIL AND PHONE CODE SVG------------------------------>

    <div class="row forgot-password-top-row margin-lock-icon">
      <!--Login to use CanPay-->
      <div class="col-2"></div>
      <div class="col-8">
        <span class="text-center">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 80 100.5"
            style="enable-background: new 0 0 90 112.5"
            xml:space="preserve"
            height="80"
            width="100"
            fill="#ffffff"
          >
            <g>
              <path
                d="M76.2,56.2h-17v-22h-9.4v-7.9c0-11.2-9.1-20.2-20.2-20.2S9.4,15.2,9.4,26.4v7.9H0v40.5h28.1c1.9,5.3,7,9.1,12.9,9.1h35.1
		C83.8,83.8,90,77.6,90,70S83.8,56.2,76.2,56.2z M12.4,26.4c0-9.5,7.7-17.2,17.2-17.2s17.2,7.7,17.2,17.2v7.9H12.4V26.4z M3,71.8
		V37.3h53.2v19H41.1c-2.2,0-4.3,0.5-6.1,1.4L34.4,54c1-1.1,1.5-2.6,1.5-4.1c0-3.5-2.8-6.3-6.3-6.3s-6.3,2.8-6.3,6.3
		c0,1.5,0.5,3,1.5,4.1l-1.5,9.6l1.5,1.7h3.3c-0.5,1.5-0.8,3-0.8,4.7c0,0.6,0,1.1,0.1,1.7C27.4,71.7,3,71.7,3,71.8z M29.6,62.4h-3.1
		l1.3-8.6l-0.5-1.4c-0.7-0.6-1.1-1.5-1.1-2.5c0-1.8,1.5-3.3,3.3-3.3c1.8,0,3.3,1.5,3.3,3.3c0,0.9-0.4,1.8-1.1,2.5l-0.5,1.3l0.9,5.7
		C31.2,60.3,30.3,61.3,29.6,62.4z M76.2,80.8H41.1c-6,0-10.8-4.8-10.8-10.8s4.8-10.8,10.8-10.8h35.1c6,0,10.8,4.8,10.8,10.8
		S82.2,80.8,76.2,80.8z"
              />
              <path
                d="M44.9,67.1c-1.6,0-2.9,1.3-2.9,2.9s1.3,2.9,2.9,2.9c1.6,0,2.9-1.3,2.9-2.9S46.5,67.1,44.9,67.1z"
              />
              <path
                d="M54.1,67.1c-1.6,0-2.9,1.3-2.9,2.9s1.3,2.9,2.9,2.9S57,71.6,57,70S55.7,67.1,54.1,67.1z"
              />
              <circle cx="63.2" cy="70" r="2.9" />
              <path
                d="M72.3,67.1c-1.6,0-2.9,1.3-2.9,2.9s1.3,2.9,2.9,2.9s2.9-1.3,2.9-2.9S73.9,67.1,72.3,67.1z"
              />
            </g>
          </svg>
        </span>
      </div>
      <div class="col-2"></div>
    </div>

    <!-------------------------------PHONE CODE TITLE------------------------------>

    <div class="row icon-space">
      <div class="col-1"></div>
      <div class="col-10">
        <span class="text-center">
          <label class="enter-code-text-style">
            Enter the code sent to you by
            <br />
            Email or Text Message
          </label>
        </span>
      </div>
      <div class="col-1"></div>
    </div>

    <!-------------------------------EMAIL OR TEXT MESSAGE CODE code title------------------------------>

    <div class="row forgotpin-middle-space">
      <div class="col-12">
        <input
          v-model="verification_otp"
          placeholder="Enter verification code"
          class="form-control otp-curser code-input"
          @keypress="isNumber($event)"
          type="text"
          maxlength="6"
          inputmode="numeric"
          required
          v-bind:style="{ 'border-color': pin1_color }"
        />
      </div>
    </div>

    <!-------------------------- Reenter QUICK ACcess PIN--------------------->

    <div class="row forgotpin-middle-space">
      <div class="col-12">
        <button type="button" class="btn-login" @click="verifyCode">
          Next
        </button>
      </div>
    </div>

    <!-------------------------------MODAL------------------------------>

    <!-----------------------  MODAL FOR SUCCESS  !---------------->

    <div>
      <b-modal
        ref="success-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        no-close-on-backdrop
        id="success-modal"
        centered
      >
        <div class="color">
          <div class="purchaserpower-modal-text">
            <div class="pin-success-top-spacing"></div>
            <div class="row" style="margin-bottom: 5px">
              <div class="col-12 text-center">
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 100 125"
                  style="enable-background: new 0 0 100 125"
                  xml:space="preserve"
                  height="80"
                  width="80"
                  fill="#1b9142"
                  class="succes-svg-padding"
                >
                  <g>
                    <g>
                      <path
                        d="M50.2,99.7c-27,0-49-21.9-49-48.9S23.2,2,50.2,2s49,21.9,49,48.9S77.2,99.7,50.2,99.7z M50.2,7.1
			C26,7.1,6.4,26.7,6.4,50.8S26,94.6,50.2,94.6S94.1,75,94.1,50.8S74.4,7.1,50.2,7.1z"
                      />
                    </g>
                    <g>
                      <polygon
                        points="47.4,69.2 29,52.3 32.5,48.5 46.6,61.4 67,31.7 71.3,34.7 		"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </div>
            <div class="d-block text-center">
              <label class="success-popup-style">Success!</label>
            </div>
            <div class="success-bottom-spacing"></div>
          </div>
        </div>
      </b-modal>
    </div>

    <div
      class="row btn-resend-code"
      v-if="!codesentFlag && timerCount == 0 && resentOtp != 3"
    >
      <button
        type="button"
        class="btn-ResendCode"
        @click="consumerAccountVerifyCodeResend"
      >
        Resend Code
      </button>
    </div>
    <div class="row btn-resend-code" v-else-if="timerCount != 0">
      <button type="button" class="btn-ResendCode" disabled>
        <span class="enter-otp-title-style">
          Resend Code After &nbsp; &nbsp; &nbsp; &nbsp;
          {{ timerCount }} Seconds</span
        >
      </button>
    </div>
    <div class="row btn-resend-code" v-else>
      <button type="button" class="btn-ResendCode" disabled>
        <span class="enter-otp-title-style"> Try Again Some Other Time </span>
      </button>
    </div>
    <!-- MODAL FOR VALIDATION ERROR MESSAGES -->
    <div>
      <b-modal
        ref="validation-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        id="validation-modal"
        centered
      >
        <div class="color">
          <div class="purchaserpower-modal-text">
            <div class="d-block text-center">
              <label class="purchasepower-def-label">
                {{ error_message }}
              </label>
            </div>
            <br />
            <br />
            <div class="text-center">
              <button
                type="button"
                class="mx-auto col-10 offset-1 btn-black"
                style="height: 60px"
                @click="hidevalidationModal"
              >
                <label class="purchasepower-modal-ok-label">OK</label>
              </button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
/**
 * write a component's description
 */
import api from "../../api/login.js";

export default {
  name: "EnterForgotPin",
  components: {},
  /**
   * @description
   * @returns {any}
   */
  data() {
    return {
      show: false,
      re_entershow: false,
      error_message: "",
      phoneCode: false,
      quickaccessPin: false,
      inputtype: "password",
      quick_inputtype: "password",
      loginPin: "",
      verification_otp: "",
      pin1_color: "",
      phone_pin1_color: "",

      UserModel: {
        login_pin: "",
        loginpinColor: "",
        confirmloginPin: "",
        confirmloginPinColor: "",
      },
      email: "",
      phone: "",
      token: "",
      codesentFlag: false,
      timerCount: 60,
      resentOtp: 1,
    };
  },
  /**
   * @description
   */
  mounted: function () {
    let self = this;
    if(localStorage.getItem('consumer_token')){
        self.$router.push('/pay')
    }else{
      this.$root.$emit("show_header", true);
      self.phone = atob(window.location.href.split("/").splice(-1, 1)[0]);
      self.email = atob(window.location.href.split("/").splice(-2, 1)[0]);
    }
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    showValidationModal(msg) {
      this.error_message = msg;
      this.$refs["validation-modal"].show();
    },
    hidevalidationModal() {
      this.error_message = "";
      this.$refs["validation-modal"].hide();
    },
    showModal() {
      let self = this;
      this.$refs["pin-success-modal"].show();
    },
    /**
     * @description
     */
    hideModal() {
      this.$refs["pin-success-modal"].hide();
      this.$router.push("/login");
    },

    verifyCode() {
      let self = this;
      if (self.verification_otp.length != 6) {
        self.showValidationModal(
          "Please enter verification code"
        );
      } else if (self.verification_otp.length != 6 || self.verification_otp == null) {
        self.showValidationModal("Please enter verification otp");
      } else {
        var request = {
          email_code: self.verification_otp,
          phone_code: self.verification_otp,
          email: self.email,
          phone: self.phone,
        };
        api
          .consumerAccountVerifyCode(request)
          .then((response) => {
            if (response.code == 200) {
              this.$refs["success-modal"].show();
              setTimeout(function () {
                self.$refs["success-modal"].hide();
              }, 2000);

              self.token = response.data.token;
              localStorage.removeItem("consumer_token");
              localStorage.removeItem("consumer_login_response");
              localStorage.setItem("check_bank", true);
              localStorage.setItem("consumer_token", response.data.token);
              localStorage.setItem(
                "consumer_login_response",
                JSON.stringify(response.data)
              );
              if (response.data.login) {
                localStorage.setItem("overlay_show", true);
              }
              self.$router.push("/pay");
            }
          })
          .catch(function (error) {
            self.showValidationModal(error.response.data.message);
          });
      }
    },

    consumerAccountVerifyCodeResend: function () {
      if (this.resentOtp == 1) {
        this.timerCount = 120;
        this.codesentFlag = false;
        this.otp = "";
        let self = this;
        this.codesentFlag = true;
        this.resentOtp++;
        var request = {
          email: self.email,
          phone: self.phone,
          resend:1
        };
        api
          .consumerAccountVerifyCodeResend(request)
          .then((response) => {})
          .catch((err) => {
            if (err.response.data.code == 598) {
              console.log(this.resentOtp);
              console.log("sad");
            }
          });
      } else if (this.resentOtp == 2) {
        this.timerCount = 180;

        this.codesentFlag = false;
        this.otp = "";
        let self = this;
        var request = {
          email: self.email,
          phone: self.phone,
          resend:1
        };
        this.codesentFlag = true;
        this.resentOtp++;
        api
          .consumerAccountVerifyCodeResend(request)
          .then((response) => {})
          .catch((err) => {
            if (err.response.data.code == 598) {
              console.log(this.resentOtp);
              console.log("test");
            }
          });
      }
    },
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            // alert();
            this.timerCount--;
          }, 1000);
        } else {
          this.codesentFlag = false;
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
};
</script>
<style>
::placeholder {
  color: rgb(0, 0, 0);
  opacity: 1;
  font-size: 14px;
  font-weight: bold;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
}
#pay-modal-center___BV_modal_content_ {
  border-radius: 10px;
  margin: 10px;
  background-color: #ffffff;
}
#pay-modal-center___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
#success-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
</style>
